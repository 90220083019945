<template>
  <div class="c-favorites">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quam semper id adipiscing.
      <br>
      Est tincidunt sem dui vitae ut eget.
    </p>
    <div
      v-if="categories.length > 0"
      class="c-favorites__cards"
    >
      <MachineCard
        v-for="machine in machines"
        :key="machine.vending_machine_id"
        :machine="machine"
      />
    </div>
    <pagination
      v-model="page"
      :records="100"
      :per-page="8"
      :options="{
        chunk: 5,
      }"
      @paginate="callback"
    />
  </div>
</template>

<script src="./Favorites.controller.js"></script>
<style lang="scss" src="./Favorites.styles.scss"></style>
