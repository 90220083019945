import { MachineCard } from "@/components";
import { mapGetters } from "vuex";

export default {
  name: "Favorites",
  components: {
    MachineCard
  },
  data: () => ({
    page: 1,
    // machines: [
    //   {
    //     id: 1,
    //     lat: 51.260197,
    //     long: 4.402771,
    //     imgPath: require("@/assets/images/machine01.png"),
    //     name: "Broodautomaat 24",
    //     address: "herfstlaan 9, Deinze",
    //     distance: "12.6",
    //     openingTimes: "09:00 - 12:00 / 13:00 - 18:00",
    //     verified: true
    //   },
    //   {
    //     id: 2,
    //     lat: 50.260197,
    //     long: 3.402771,
    //     imgPath: require("@/assets/images/machine02.png"),
    //     name: "Kjure Sint-Denijs",
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem",
    //     distance: "12.6",
    //     openingTimes: "24h",
    //     verified: true
    //   },
    //   {
    //     id: 3,
    //     lat: 51.060197,
    //     long: 4.902771,
    //     imgPath: require("@/assets/images/machine02.png"),
    //     name: "Kjure Sint-Denijs",
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem 9051",
    //     distance: "12.6",
    //     openingTimes: "overdag",
    //     verified: false
    //   },
    //   {
    //     id: 4,
    //     lat: 51.060197,
    //     long: 4.702771,
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem 9051",
    //     distance: "5.9",
    //     openingTimes: "overdag",
    //     verified: false
    //   },
    //   {
    //     id: 5,
    //     lat: 51.060197,
    //     long: 5.702771,
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem 9051",
    //     distance: "9.2",
    //     openingTimes: "overdag",
    //     verified: false
    //   },
    //   {
    //     id: 6,
    //     lat: 51.060197,
    //     long: 5.702771,
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem 9051",
    //     distance: "9.2",
    //     openingTimes: "overdag",
    //     verified: false
    //   },
    //   {
    //     id: 6,
    //     lat: 51.060197,
    //     long: 5.702771,
    //     address: "kortrijksesteenweg 1208,\nSint-Denijs-Westrem 9051",
    //     distance: "9.2",
    //     openingTimes: "overdag",
    //     verified: false
    //   }
    // ]
  }),
  computed: {
    ...mapGetters("machine", {
      machines: "getMachines",
      categories: "getCategories"
    }),
  },
  methods:{
    onPageClick(event){
      this.currentPage = event;
    },
    callback() {
     console.log(this.page)
    },
  }
};
